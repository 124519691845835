<template>
	<div class="f82m4">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>F82-M4 ASSIST M4</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									F82-M4 ASSIST M4
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div id="main-info" class="section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-6">
						<h2 class="px-2">F82-M4 ASSIST M4</h2>
						<h3>Concept</h3>
						<p class="p-2">
							ASSIST M4
							は、最速のストリートカーをコンセプトにチューニングしています。
							ソフトな乗り心地でありながらショックアブソーバーが底突きすることなくハードなスプリングをしなやかにコントロールする技術は、高性能ショックアブソーバーの
							セッティングでサスペンションが繊細に動くようにマウントやアームのチューニングと独自のジオメトリーによってサーキットを充分に楽しめてストリートでもストレス
							の無い乗り心地で高次元に実現しています。世界から優秀なチューニングパーツを厳選して採用しており、メーカーや専門技術者との共同による
							LAPTORR 開発チーム ででもあります。
						</p>
						<p class="p-2">
							速いクルマづくりを評価するためにタイムアタックやバトルへ積極的に取り組んでいます。走らせるたびにクルマは進化し続けており、プロドライバー
							による評価も大きく貢献しています。速さをキープしながらストリートのフィーリングを最良にするためのチューニングも同時にしています。
							私たちは、これらで培った知識と技術を BMW
							チューニングに活かしています。
						</p>
					</div>
					<div class="col-12 col-md-6 mb-4">
						<img
							src="/img/concept-cars/f82-m4/concept.jpg"
							class="img-fluid"
							alt="画像08"
						/>
						<div class="my-4">
							<table class="table table-bordered">
								<tbody>
									<tr>
										<th scope="row">車両</th>
										<td>BMW F82 M4</td>
									</tr>
									<tr>
										<th scope="row">仕様</th>
										<td>6速マニュアル 左ハンドル</td>
									</tr>
									<tr>
										<th scope="row">ボディカラー</th>
										<td>B68 ヤスマリナブルーメタリック</td>
									</tr>
									<tr>
										<th scope="row">モデル</th>
										<td>2014年</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="achievement" class="block section-padding-50-0">
			<div class="container">
				<h3>Achievement</h3>
				<div class="row mb-5">
					<div class="col-12 col-md-4">
						<img
							src="/img/concept-cars/f82-m4/achievement/01.jpg"
							class="img-fluid"
							alt="画像08"
						/>
					</div>
					<div class="col-12 col-md-8">
						<div class="title">
							<h6>Hot Version 峠最強伝説 魔王 2019</h6>
							<h4>魔王 ASSIST M4</h4>
						</div>
						<p class="m-0 p-2">
							2018 年 11 月の魔王決定戦で WINNER となり、2019
							年度の魔王になりました。 1 対 1
							で峠をバトルして引き離せれば勝ち、ベタ付けされれば負け、前後入替えで
							2 回戦って勝敗を決定
							します。トーナメントで勝ち続けた者が魔王になります。
							軽量でハイパワー車が有利とされていた魔王決定戦で 1,500kg 超えの M4
							が魔王になれたのはコーナリン グフォース無しでは語れない栄光です。
						</p>
					</div>
				</div>
				<div class="row mb-4">
					<div class="col-12 col-md-4">
						<img
							src="/img/concept-cars/f82-m4/achievement/02.jpg"
							class="img-fluid"
							alt="画像08"
						/>
					</div>
					<div class="col-12 col-md-8">
						<div class="title">
							<h6>Time attack</h6>
							<h4>タイムアタック</h4>
						</div>
						<ul class="mb-2 ml-2">
							<li class="font-weight-bold">
								2022 年 2 月 2' 13" 509 ドライバー 谷口信輝 REV SPEED
								鈴鹿サーキットランミーティング
							</li>
							<li class="font-weight-bold">
								2022 年 2 月 0' 58" 356 ドライバー 谷口信輝 Attack 筑波
							</li>
							<li class="font-weight-bold">
								2021 年 3 月 1' 37" 352 ドライバー 谷口信輝 Attack 岡山
							</li>
							<li class="font-weight-bold">
								2019 年 2 月 0' 58" 818 ドライバー 谷口信輝 Attack 筑波
							</li>
							<li class="font-weight-bold">
								2018 年 2 月 2' 15" 598 ドライバー 谷口信輝 Attack 鈴鹿
							</li>
						</ul>
						<!--<p class="m-0 p-2">
							2019 年まではノーマルタービンで 2020
							年からハイフローターボでパワーアップしていますので
							今後のタイムアタックでタイムアップは期待できそうです。
						</p>-->
					</div>
				</div>
			</div>
		</div>

		<div class="block section-padding-50-0">
			<div class="container">
				<div class="row">
					<div id="photo_gallery" class="col-12 col-sm-6">
						<h3>Photo Gallery</h3>

						<div class="row images">
							<div v-for="item in 35" :key="'photo' + item" class="col-12">
								<img
									:src="'/img/concept-cars/f82-m4/images/' + item + '.jpg'"
									:class="{
										'img-fluid': true,
										'img-thumbnail': true,
										'mb-3': true,
										'd-none': item == 1 ? false : true,
									}"
									:alt="'画像' + item"
									data-size="600x400"
								/>
								<p
									:class="{
										caution: true,
										'd-none': item == 1 ? false : true,
									}"
								>
									※ 写真をクリックするとギャラリーが見れます
								</p>
							</div>
						</div>

						<!-- Root element of PhotoSwipe. Must have class pswp. -->
						<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
							<div class="pswp__bg"></div>
							<div class="pswp__scroll-wrap">
								<div class="pswp__container">
									<div class="pswp__item"></div>
									<div class="pswp__item"></div>
									<div class="pswp__item"></div>
								</div>
								<div class="pswp__ui pswp__ui--hidden">
									<div class="pswp__top-bar">
										<div class="pswp__counter"></div>
										<button
											class="pswp__button pswp__button--close"
											title="Close (Esc)"
										></button>
										<button
											class="pswp__button pswp__button--share"
											title="Share"
										></button>
										<button
											class="pswp__button pswp__button--fs"
											title="Toggle fullscreen"
										></button>
										<button
											class="pswp__button pswp__button--zoom"
											title="Zoom in/out"
										></button>
										<div class="pswp__preloader">
											<div class="pswp__preloader__icn">
												<div class="pswp__preloader__cut">
													<div class="pswp__preloader__donut"></div>
												</div>
											</div>
										</div>
									</div>
									<div
										class="
											pswp__share-modal
											pswp__share-modal--hidden
											pswp__single-tap
										"
									>
										<div class="pswp__share-tooltip"></div>
									</div>
									<button
										class="pswp__button pswp__button--arrow--left"
										title="Previous (arrow left)"
									></button>
									<button
										class="pswp__button pswp__button--arrow--right"
										title="Next (arrow right)"
									></button>
									<div class="pswp__caption">
										<div class="pswp__caption__center"></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="movies" class="col-12 col-sm-6">
						<div id="movies" class="headline">
							<h3>Movies</h3>
						</div>
						<div class="row item">
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/_nXnTKHmX54"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2022.02.24</small><br /><a
										href="https://youtu.be/_nXnTKHmX54"
										target="_blank"
										>谷口信輝 REVSPEED鈴鹿
										<small class="badge badge-primary">NEW</small></a
									>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/U_NDCFnMMi0"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2022.02.23</small><br /><a
										href="https://youtu.be/U_NDCFnMMi0"
										target="_blank"
										>谷口信輝 ATTACK TSUKUBA
										<small class="badge badge-primary">NEW</small></a
									>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/U-iGx3gokos"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2021.03.09</small><br /><a
										href="https://youtu.be/U-iGx3gokos"
										target="_blank"
										>谷口信輝 Attack岡山
									</a>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/FQ2i-uoZC9Y"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2019.02.23</small><br /><a
										href="https://youtu.be/FQ2i-uoZC9Y"
										target="_blank"
										>谷口信輝 Attack筑波
									</a>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/o5ShNJdyP0k"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2018.02.09</small><br /><a
										href="https://youtu.be/o5ShNJdyP0k"
										target="_blank"
										>谷口信輝 Attack鈴鹿</a
									>
								</h5>
							</div>
							<!--<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/yhTsNtilcZ8"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2016.11.03</small><br /><a
										href="https://youtu.be/yhTsNtilcZ8"
										target="_blank"
										>谷口信輝 鈴鹿サーキット テスト</a
									>
								</h5>
							</div>-->
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/Bt-9jA34I2U"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2016.11.26</small><br /><a
										href="https://youtu.be/Bt-9jA34I2U"
										target="_blank"
										>谷口信輝 セントラルサーキット テスト</a
									>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/ufz326cJmbw"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2016.12.02</small><br /><a
										href="https://youtu.be/ufz326cJmbw"
										target="_blank"
										>谷口信輝 筑波スーパーバトル</a
									>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="spec" class="block section-padding-50-0">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div id="movies" class="headline">
							<h3>Spec</h3>
						</div>

						<h4 class="mb-3">ENGINE COMPONENT</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a href="https://item.assist-kyoto.co.jp/bm3/"
									><img
										src="/img/concept-cars/f82-m4/tuning/1211.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
								/></a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">DMEチューニング</span>
								<h5>
									<a href="https://item.assist-kyoto.co.jp/bm3/"
										>bm3 (bootmod3) with ASSIST tuning</a
									>
								</h5>
								<h6>ビーエム3 (ブーモ3) ASSISTオリジナル</h6>
								<p>
									DMEのロックを解除してOBD2コネクターからMAPの読み書きすることができます。オリジナルデータの作成も可能でデータは専用クラウドに保存します。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<a href="https://item.assist-kyoto.co.jp/plasma-direct/">
									<img
										src="/img/concept-cars/f82-m4/tuning/1231.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">イグニションコイル</span>
								<h5>
									<a href="https://item.assist-kyoto.co.jp/plasma-direct/"
										>OKADA PROJECTS PLASMA DIRECT</a
									>
								</h5>
								<h6>オカダプロジェクツ プラズマダイレクト</h6>
								<p>強化タイプのイグニションでエンジン補正を抑えます。</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/1241.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">スパークプラグ</span>
								<h5>NGK SPARK PLUG Iridium</h5>
								<h6>NGKスパークプラグ 高熱価イリジウム</h6>
								<p>ハイブーストに適した熱価のスパークプラグ</p>
							</div>
						</div>

						<h4 class="mb-3">INTAKE</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a
										href="https://store.assist-kyoto.co.jp/products/513-gruppem-ram-air-system-for-f30-320i-328i"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/1321.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアクリーナーボックス</span>
								<h5>
									<a
										href="https://store.assist-kyoto.co.jp/products/513-gruppem-ram-air-system-for-f30-320i-328i"
										>GruppeM RAM AIR SYSTEM</a
									>
								</h5>
								<h6>グループエム ラムエアシステム</h6>
								<p>パワーアップで空気量アップをスムーズに取り込みます。</p>
							</div>
							<div class="col-sm-3 col-6">
								<a href="https://item.assist-kyoto.co.jp/intercooler/"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/1322.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアクリーナーボックス</span>
								<h5>
									<a href="https://item.assist-kyoto.co.jp/intercooler/"
										>INTERCOOLER</a
									>
								</h5>
								<h6>インタークーラー</h6>
								<p>冷却性能の高さから定番採用しました。</p>
							</div>
						</div>

						<h4 class="mb-3">TURBO</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a href="https://item.assist-kyoto.co.jp/pure-turbos/"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/1451.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ハイフローターボ</span>
								<h5>
									<a href="https://item.assist-kyoto.co.jp/pure-turbos/"
										>PURE TURBOS STAGE-2 PLUS</a
									>
								</h5>
								<h6>ピュアターボス ステージ２プラス</h6>
								<p>ハイフローSTAGE２</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/1461.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">チャージパイプ</span>
								<h5>ER（Evolution Racewerks）Charge-pipes and J-pipe</h5>
								<h6>
									イーアール（エボリューションレースワークス）チャージパイプ＆
									Jパイプ
								</h6>
								<p>ブースト圧のキープとパイプの破損予防。</p>
							</div>
						</div>

						<h4 class="mb-3">EXHAUST</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/m/products/exhaust-system-f826tb?variant=19886034026592"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/1851.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ダウンパイプ</span>
								<h5>
									<a
										href="https://laptorr.com/collections/m/products/exhaust-system-f826tb?variant=19886034026592"
										>LAPTORR DOWN PIPES</a
									>
								</h5>
								<h6>ラプタ ダウンパイプ</h6>
								<p>触媒を無くして排圧と排気抵抗を軽減しています。</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/m/products/exhaust-system-f826tb?variant=19807813271648"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/1811.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エキゾースト</span>
								<h5>
									<a
										href="https://laptorr.com/collections/m/products/exhaust-system-f826tb?variant=19807813271648"
										>LAPTORR EXHAUST SYSTEM F826tb</a
									>
								</h5>
								<h6>ラプタ エキゾーストシステム F826tb</h6>
								<p>
									２WAYモード。オープンでストレート。Φ70フラップ採用。クローズでコンフォート（排気効率の良いストレートタイプで高回転域がスムーズ）
								</p>
							</div>
						</div>

						<h4 class="mb-3">DRIVE TRAIN</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a href="https://item.assist-kyoto.co.jp/lsd/"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/2911.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ディファレンシャル</span>
								<h5>
									<a href="https://item.assist-kyoto.co.jp/lsd/"
										>LAPTORR LSD F8XM by OS-GIKEN TCD
									</a>
								</h5>
								<h6>ラプタ LSD F8XM （オーエス技研 TCD）</h6>
								<p>
									オリジナルのセッティングで電子制御をキャンセルしたディファレンシャル。
								</p>
							</div>
						</div>

						<h4 class="mb-3">FOOTWORK</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/3001.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションキット</span>
								<h5>QUANTUM T3-CR ASSIST tuning</h5>
								<h6>クアンタム T3-CR ASSISTオリジナル</h6>
								<p>
									リザーバータンク、2WAY減衰調整タイプ、フロント全長調整式ダンパーストリートからタイムアタックまで使用できる超ワイドレンジの車高調サスキット。ダンパーの仕様変更は自社アップデート。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/3121.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>
									LAPTORR MONO BALL BEARING MOUNT for Front Top with Camber
									/Caster Adjustabl
								</h5>
								<h6>
									ラプタ モノボールベアリングマウント フロント トップ
									キャンバー／キャスター調整式
								</h6>
								<p>キャンバ、キャスター調整タイプ</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/m/products/312-01251-%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88%E3%83%94%E3%83%AD-%E3%83%86%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AD%E3%83%83%E3%83%89-2-pcs?variant=31448970920032"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/3122.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>
									<a
										href="https://laptorr.com/collections/m/products/312-01251-%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88%E3%83%94%E3%83%AD-%E3%83%86%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AD%E3%83%83%E3%83%89-2-pcs?variant=31448970920032"
										>LAPTORR MONO BALL BEARING MOUNT for FRONT Tension Strut</a
									>
								</h5>
								<h6>
									ラプタ モノボールベアリングマウント フロント テンションアーム
								</h6>
								<p>
									ネガキャンバー、ワイドタイヤを装着による無駄な動きを抑えます。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/3131.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションアーム</span>
								<h5>LAPTORR FRONT LOWER ARM Adjustable</h5>
								<h6>ラプタ フロント ロアアーム 調整式</h6>
								<p>
									フロントサスの強化。アッパーマウントとセットでネガキャンバー・ハイキャスターにします。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/m/products/332-01322-%E3%83%AA%E3%82%A2%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88%E3%83%94%E3%83%AD-for-%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%90%E3%83%BC%E3%82%A2%E3%83%BC%E3%83%A0?variant=31901955260512"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/3321.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>
									<a
										href="https://laptorr.com/collections/m/products/332-01322-%E3%83%AA%E3%82%A2%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88%E3%83%94%E3%83%AD-for-%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%90%E3%83%BC%E3%82%A2%E3%83%BC%E3%83%A0?variant=31901955260512"
										>LAPTORR MONO BALL BEARING MOUNT for Camber Arm IN-side</a
									>
								</h5>
								<h6>
									ラプタ モノボールベアリングマウント リア キャンバーアーム IN側
								</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/products/332-01341-%E3%83%A2%E3%83%8E%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%99%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%B0%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88-for-%E3%83%AA%E3%82%A2%E3%83%88%E3%83%AC%E3%83%BC%E3%83%AA%E3%83%B3%E3%82%B0%E3%82%A2%E3%83%BC%E3%83%A0"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/3322.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>
									<a
										href="https://laptorr.com/products/332-01341-%E3%83%A2%E3%83%8E%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%99%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%B0%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88-for-%E3%83%AA%E3%82%A2%E3%83%88%E3%83%AC%E3%83%BC%E3%83%AA%E3%83%B3%E3%82%B0%E3%82%A2%E3%83%BC%E3%83%A0"
										>LAPTORR MONO BALL BEARING MOUNT for Trailing Arm</a
									>
								</h5>
								<h6>
									ラプタ モノボールベアリングマウント リア トレーリングアーム
								</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/m/products/332-01361-%E3%83%A2%E3%83%8E%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%99%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%B0%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88-for-%E3%83%AA%E3%82%A2%E3%82%A2%E3%83%83%E3%83%91%E3%83%BC%E3%82%A2%E3%83%BC%E3%83%A0"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/3323.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>
									<a
										href="https://laptorr.com/collections/m/products/332-01361-%E3%83%A2%E3%83%8E%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%99%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%B0%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88-for-%E3%83%AA%E3%82%A2%E3%82%A2%E3%83%83%E3%83%91%E3%83%BC%E3%82%A2%E3%83%BC%E3%83%A0"
										>LAPTORR MONO BALL BEARING MOUNT for Rear Upper Arm</a
									>
								</h5>
								<h6>ラプタ モノボールベアリングマウント リア アッパーアーム</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/products/333-01301-f8xm-%E3%83%AA%E3%82%A2%E3%83%AD%E3%82%A2%E3%82%A2%E3%83%BC%E3%83%A0-%E3%82%A2%E3%82%B8%E3%83%A3%E3%82%B9%E3%82%BF%E3%83%96%E3%83%AB-for-f80-82-m3-m4-f87-m2-m2c?variant=34632072757401"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/3331.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションアーム</span>
								<h5>
									<a
										href="https://laptorr.com/products/333-01301-f8xm-%E3%83%AA%E3%82%A2%E3%83%AD%E3%82%A2%E3%82%A2%E3%83%BC%E3%83%A0-%E3%82%A2%E3%82%B8%E3%83%A3%E3%82%B9%E3%82%BF%E3%83%96%E3%83%AB-for-f80-82-m3-m4-f87-m2-m2c?variant=34632072757401"
										>LAPTORR REAR LOWER ARM Adjustable</a
									>
								</h5>
								<h6>ラプタ リア ロアアーム 調整式</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
						</div>

						<h4 class="mb-3">TIRE</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/3701.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">タイヤ</span>
								<h5>YOKOHAMA ADVAN A052</h5>
								<h6>ヨコハマ アドバン A052</h6>
								<p>(F/R) 295/35R-18<br />ADVAN最強のラジアルタイヤ。</p>
							</div>
						</div>

						<h4 class="mb-3">WHEELS</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/3601.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ホイール</span>
								<h5>RAYS VOLKRACING TE37-SAGA LAPTORR-ORIGINAL 18"-11.0J</h5>
								<h6>
									レイズ ヴォルクレーシング TE37-SAGA ラプタオリジナル 18"-11.0J
								</h6>
								<p>
									(F/R) 18x11.0J +37<br />295/35-18サイズのタイヤを装着するためのM4専用ASSISTオリジナル。リアは加工無しで装着可能。フロントはフェンダー加工して装着。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/3602.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ホイール</span>
								<h5>BBS RI-A LAPTORR-ORIGINAL18"-11.0J</h5>
								<h6>BBS RI-A ラプタオリジナル 18"-11.0J</h6>
								<p>
									(F/R) 18x11.0J +37<br />295/35-18サイズのタイヤを装着するためのM4専用ASSISTオリジナル。リアは加工無しで装着可能。フロントはフェンダー加工して装着。
								</p>
							</div>
						</div>

						<h4 class="mb-3">BRAKE</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a
										href="https://item.assist-kyoto.co.jp/endless-brake-system/"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/3401.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ブレーキシステム</span>
								<h5>
									<a
										href="https://item.assist-kyoto.co.jp/endless-brake-system/"
										>ENDLESS BRAKE SYSTEM</a
									>
								</h5>
								<h6>エンドレス ブレーキシステム</h6>
								<p>
									(F/R) 鍛造モノブロック/メッキ 6ピストンキャリパー<br />Fキャリパーピストンは鍛造＆インシュレーターホールあり<br />ローター(84fin)
									(F) 396mmx36mm (R) 380mmx34mm
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/3431.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ブレーキパッド</span>
								<h5>ENDLESS CIRCUIT COMPOUND</h5>
								<h6>エンドレス サーキットコンパウンド</h6>
								<p>
									摩擦係数ラインナップでドライバーに合わせたセッティングが可能。
								</p>
							</div>
						</div>

						<h4 class="mb-3">EXTERIOR</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/5101.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ワイドボディ</span>
								<h5>FRONT FENDER WIDE</h5>
								<h6>Fフェンダーワイド化</h6>
								<p>
									ADVAN A052 295/35-18を装着するためにワイド化<br />材質はアルミニウムのためフェンダー追加で延長しています。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/m/products/front-spoiler-with-side-spoiler-f8xm"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/5121.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアロダイナミクス</span>
								<h5>
									<a
										href="https://laptorr.com/collections/m/products/front-spoiler-with-side-spoiler-f8xm"
										>LAPTORR FRONT SPOILER with SIDE SPOILER</a
									>
								</h5>
								<h6>ラプタ フロントスポイラー with サイドスポイラー</h6>
								<p>
									ダウンフォース、整流、クーリング、効果のあるエアロダイナミクス
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/m/products/513-02102-under-spoiler-for-f80-82-m3-m4"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/5122.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
								</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアロダイナミクス</span>
								<h5>
									<a
										href="https://laptorr.com/collections/m/products/513-02102-under-spoiler-for-f80-82-m3-m4"
										>LAPTORR UNDER SPOILER</a
									>
								</h5>
								<h6>ラプタ アンダースポイラー</h6>
								<p>
									フロントスポイラーとの装着でダウンフォースを発生しています。オイルクーラー排出効果の高い開口部になっています。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/5141.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアロダイナミクス</span>
								<h5>3D Design SIDE SKIRT</h5>
								<h6>3Dデザイン サイドスカート</h6>
								<p>3104-28211 ドライカーボン</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/5171.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアロダイナミクス</span>
								<h5>VOLTEX GT-WING</h5>
								<h6>ボルテックス GTウィング</h6>
								<p></p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/m/products/518-01001-gt-wing-stay"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/5172.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアロダイナミクス</span>
								<h5>
									<a
										href="https://laptorr.com/collections/m/products/518-01001-gt-wing-stay"
										>LAPTORR GT WING STAY T001</a
									>
								</h5>
								<h6>ラプタGTウィングステー T001</h6>
								<p>
									GTウィングを最適な位置へ再セットアップしたステー。アルミニウム製
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/6301.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">レトロフィット</span>
								<h5>BMW LCI TAIL LENS</h5>
								<h6>BMW LCI テールレンズ</h6>
								<p>後期モデルのテールレンズ。</p>
							</div>
						</div>

						<h4 class="mb-3">INTERIOR</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/5211.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">シート</span>
								<h5>RECARO PRO RACER RMS 2600A (CARBON)</h5>
								<h6>レカロ プロレーサー RMS 2600A（カーボン）</h6>
								<p>
									ボディデザインとの相性は抜群。剛性、ドライビングインフォメーション、通気性に優れている。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/m/products/522-00702-%E3%82%B7%E3%83%BC%E3%83%88%E3%83%AC%E3%83%BC%E3%83%AB-%E3%82%BC%E3%83%AD%E3%82%AA%E3%83%95%E3%82%BB%E3%83%83%E3%83%88-for-motorsport?variant=39429726699673"
										>
									<img
										src="/img/concept-cars/f82-m4/tuning/5212.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">シート</span>
								<h5>
									<a
										href="https://laptorr.com/collections/m/products/522-00702-%E3%82%B7%E3%83%BC%E3%83%88%E3%83%AC%E3%83%BC%E3%83%AB-%E3%82%BC%E3%83%AD%E3%82%AA%E3%83%95%E3%82%BB%E3%83%83%E3%83%88-for-motorsport?variant=39429726699673"
										>LAPTORR SEAT RAIL ZERO OFFSET</a
									>
								</h5>
								<h6>ラプタ シートレール ゼロオフセット</h6>
								<p>
									シートとステアリングとのオフセットをゼロ。メーカーに比べレールの強度アップされている。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/f82-m4/tuning/6101.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ロガー</span>
								<h5>AIM LOGGER SYSTEM</h5>
								<h6>AIMロガーシステム</h6>
								<p>
									CANbusからロガー記録。車両の状態を詳細にみることができる。GPS搭載でラップタイムやドライビングデータもとれるため、ドライビングのスキルアップには大きな武器である。
								</p>
							</div>
						</div>
					</div>

					<!-- Blog Sidebar Area -->
					<!--<div class="col-12 col-lg-4">
						<Sidebar></Sidebar>
					</div>-->
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import PhotoSwipe from "photoswipe/dist/photoswipe.min.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.min.js";

export default {
	title: "F82-M4 ASSIST M4",
	components: {
		//Sidebar,
	},
	mounted: function () {
		const setup = () => {
			// 画像リストを作成
			const items = Array.from(document.querySelectorAll("img[data-size]")).map(
				(i) => {
					const wh = i.getAttribute("data-size").split("x");
					return {
						src: i.getAttribute("src"),
						w: parseInt(wh[0], 10),
						h: parseInt(wh[1], 10),
					};
				}
			);
			// photoswipe.htmlで定義したルート
			const pswpElement = document.querySelector(".pswp");
			// 画像クリック時にギャラリーが表示されるようにする
			document.querySelectorAll("img[data-size]").forEach((e, i) => {
				e.onclick = () => {
					const gallery = new PhotoSwipe(
						pswpElement,
						PhotoSwipeUI_Default,
						items,
						{
							shareEl: false,
							index: i,
						}
					);
					gallery.init();
				};
			});
		};
		setup();
	},
};
</script>

<style scoped lang="scss">
#main-info {
	background-color: #152535;
	h2 {
		font-size: 3rem;
		color: #fff;
	}
	h3 {
		padding: 0.5rem;
		border-bottom: 3px dotted #fff;
		color: #fff;
	}
	p {
		margin: 0 0 1rem 0;
		color: #fff;
	}
	table {
		th,
		td {
			color: #fff;
		}
	}
}
#achievement {
	.title {
		margin-bottom: 1rem;
		padding: 1rem 1rem 0.5rem 1rem;
		border-bottom: 2px solid #152535;
	}
}
#photo_grallery {
	p.caution {
		color: #152535;
		font-size: 0.8rem;
	}
}
#movies {
	small {
		font-size: 0.8rem;
	}
}
#spec {
	h4 {
		font-size: 1rem;
		padding: 1rem;
		border-bottom: 3px dotted #152535;
		background: #f4f4f4;
	}
	h5 {
		margin-bottom: 1.5rem;
		color: #152535;
		font-size: 1rem;
		font-weight: 500;
		a {
			color: #152535;
			font-size: 1rem;
			text-decoration: underline;
		}
	}
	h6 {
		padding: 0.6rem;
		color: #152535;
		font-size: 0.8rem;
		background: #f4f4f4;
	}
	p {
		color: #152535;
		font-size: 0.8rem;
	}
}
</style>
